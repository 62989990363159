<template>
  <v-container class="fill-height page-login messaging pa-0" fluid>
    <v-row>
      <v-col>
        <v-card class="pa-3 page-login__card" tile>
          <v-card-title>
            <img src="/static/m.png" alt="มูลนิธิแม่ฟ้าหลวง" width="55" />
            <h1 class="primary--text display-1">
              แบบประเมินผลการศึกษาดูงาน<br />
              มูลนิธิแม่ฟ้าหลวง
            </h1>
          </v-card-title>
          <v-card-text>
            <v-form
              ref="form"
              class="my-10"
              lazy-validation
              v-model="formValid"
            >
              <h2>ข้อตกลงเรื่องการรักษาข้อมูลส่วนตัว</h2>
              <div>
                มูลนิธิแม่ฟ้าหลวง ในพระบรมราชูปถัมภ์ (“มูลนิธิฯ”) จะเก็บ รวบรวม
                และใช้ข้อมูลที่ท่านกรอก ได้แก่ ชื่อ-นามสกุล อีเมล เบอร์โทรศัพท์
                เพื่อติดตามการนำหลักการทรงงานตามตำราแม่ฟ้าหลวงไปสู่การ
                ปฏิบัติและขยายผลได้อย่างประสิทธิภาพ มูลนิธิฯ
                รับประกันการเก็บรักษาข้อมูลส่วนบุคคลของท่านเพื่อ
                จุดประสงค์ที่ระบุไว้เท่านั้นและจะรักษาความลับของข้อมูลตลอดระยะเวลาท่ีจำเป็นต้องใช้ข้อมูลดังกล่าวและ
                จะไม่เผยแพร่ข้อมูลของท่านให้บุคคลอื่นเว้นแต่จะเป็นกรณีจำเป็นเพื่อการจัดกิจกรรมให้แก่ท่านเท่าน้ัน
              </div>
            </v-form>
          </v-card-text>
          <v-card-actions>
            <v-btn
              large
              tile
              color="primary"
              @click="handleAcceptPolicy"
              :loading="loading"
            >
              ยินยอม
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import { mapGetters } from 'vuex'
// import { uuid } from 'vue-uuid'
export default {
  name: 'AssessmentWelcome',
  components: {},
  data() {
    return {
      token: '',
      groupId: '',
      formId: '',
      tripId: '',
      themingVars: '',
      formValid: false,
      loading: false
    }
  },
  created() {
    if (this.$route.params.groupId) {
      this.groupId = this.$route.params.groupId
    } else if (this.$route.params.formId) {
      this.formId = this.$route.params.formId
    } else if (this.$route.params.tripId) {
      this.tripId = this.$route.params.tripId
    }
    this.checkAcceptPolicy()
  },
  computed: {
    ...mapGetters(['isAuthenticated', 'isAcceptPolicy', 'getToken', 'getUUID'])
    // ...mapActions(['acceptPolicy'])
  },
  methods: {
    checkAcceptPolicy() {
      if (this.isAcceptPolicy) {
        if (this.tripId) {
          this.$router.push(`/assessment/trip/${this.tripId}`)
        } else if (this.formId) {
          this.$router.push(`/assessment/form/${this.formId}`)
        } else if (this.groupId) {
          this.$router.push(`/assessment/group/${this.groupId}`)
        }
      }
    },
    handleAcceptPolicy() {
      // const token = uuid.v4()
      // this.$store.dispatch('setToken', token)
      // this.acceptPolicy()
      this.$store.dispatch('setAcceptPolicy', true)
      this.checkAcceptPolicy()
      // this.$router.push(`/evaluation/${this.tripId}`)
    }
  }
}
</script>
